@media (max-width: 767px) {
    .slider-container {
        margin-bottom: 30px;
    }

    .styled-button-slider {
        display: none;
    }

    .button-cont-slider {
        display: none;
    }
}

/* MyCarouselItem.css */

.button-cont-slider {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    text-align: center;
}



.styled-button-slider {
    background: transparent;
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 0;
    font-size: 26px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    border: 4px solid #FFFFFF;
    transition: background 0.3s, color 0.3s;
}

.styled-button-slider:hover {
    background: #c20000;
}

/*Estilos para la redireccion de marca en los slides */
.button-cont-slider-link-marca {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Centrado horizontal y vertical */
    text-align: center;
}

.styled-button-slider-link-marca {
    background: transparent;
    /* Fondo transparente */
    color: #FFFFFF;
    /* Texto blanco */
    padding: 5px 40px;
    /* Más padding en los lados (x) */
    border-radius: 25px;
    /* Bordes redondeados */
    font-size: 20px;
    /* Tamaño del texto */
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #FFFFFF;
    transition: color 0.3s, border 0.3s;
}

.styled-button-slider-link-marca:hover {
    color: #c20000;
    border: 1px solid #c20000;
}